










import { Component, Vue } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import VocabularyListComponent from '@/components/VocabularyListComponent.vue';
import { Authenticator } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter } from '@/services';
import { AxiosResponse } from 'axios';
import { UserInfo, EmptyPostPayload } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';

@Component({
  components: {
    AsyncButton, VocabularyListComponent
  }
})
export default class VocabularySummary extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  public readonly module_type: SHENGCI_MODULES = SHENGCI_MODULES.VOCABULARY;


}
